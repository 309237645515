import { Box, Skeleton, Stack, type StackProps, Tabs } from '@mantine/core'
import React from 'react'
import { RelationStatusDisplay } from '~/client/components/relation/display'
import { AppBreadcrumbs } from '~/client/components/util'
import { PageTitle } from '~/client/components/util/page-title'
import { InvestorRedFlagExplanationHoverCard } from '~/client/components/util/red-flags'
import { hooks, useCorpCryptId, useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { EnhancedCorp, EnhancedRelation, RedFlagInfo } from '~/common/enhance'

interface RelationDetailProps {
  name: string
  backLink: string
  title: string
  isSkeleton?: boolean
}

/**
 * The layout for the relation detail page, including the title, breadcrumbs, and the main content.
 * If isSkeleton is true, the title and breadcrumbs will be skeletons of the length given by the mock values passed.
 */
export const DetailHeaderLayout: React.FC<RelationDetailProps> = ({
  name,
  backLink,
  title,
  isSkeleton = false, // undefined will show the skeleton
  children,
}) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return (
    <Box mr='auto' maw={1200}>
      <PageTitle title={title} isSkeleton={isSkeleton} />
      <Skeleton visible={isSkeleton} w='fit-content'>
        <AppBreadcrumbs
          items={[
            {
              linkTitle: 'Dashboard',
              link: mkCurrentCorpRoute('dashboard'),
            },
            { linkTitle: name, link: backLink },
            { linkTitle: title, current: true },
          ]}
        />
      </Skeleton>
      {children}
    </Box>
  )
}

interface DetailLayoutProps extends StackProps {
  testId?: string
  withRelations?: boolean
  redFlagsComponent: React.ReactNode
  metadataComponent: React.ReactNode
  documentsComponent: React.ReactNode
  relationsComponent?: React.ReactNode
  data: EnhancedRelation | EnhancedCorp | undefined
  redFlagInfo: RedFlagInfo | undefined
}

const DetailStack: React.FC<StackProps> = ({ children, ...stackProps }) => {
  return (
    <Stack mt='md' gap='xl' maw={theme.other.widths.md} {...stackProps}>
      {children}
    </Stack>
  )
}

export const DetailLayout: React.FC<DetailLayoutProps> = ({
  data,
  testId,
  redFlagInfo,
  withRelations,
  redFlagsComponent,
  metadataComponent,
  relationsComponent,
  documentsComponent,
  ...stackProps
}) => {
  const { data: auth } = useCurrentCorpAuth()
  const isInvestor = auth?.level === 'investor'
  const navigationTabs = hooks.useNavigationTabs({
    tabs: [
      ...(withRelations ? (['details', 'relations'] as const) : (['details'] as const)),
      'documents',
      'red-flags',
    ],
    defaultTab: 'details',
  })

  return (
    <Tabs data-testid={testId} {...navigationTabs} mb='xl' maw={theme.other.widths.md}>
      <Tabs.List>
        <Tabs.Tab value='details' data-testid='details-tab'>
          Details
        </Tabs.Tab>
        {withRelations && <Tabs.Tab value='relations'>Relations</Tabs.Tab>}
        <Tabs.Tab data-testid='documents-tab' value='documents'>
          Documents
        </Tabs.Tab>
        <Tabs.Tab
          disabled={isInvestor}
          styles={{ tabSection: { margin: 0 } }}
          value='red-flags'
          data-testid='red-flags-tab'
          rightSection={
            isInvestor ? (
              <InvestorRedFlagExplanationHoverCard />
            ) : data && redFlagInfo ? (
              <RelationStatusDisplay item={data} redFlagInfo={redFlagInfo} size='sm' />
            ) : null
          }
        >
          Red Flags
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='details'>
        <DetailStack {...stackProps}>{metadataComponent}</DetailStack>
      </Tabs.Panel>
      {withRelations && (
        <Tabs.Panel value='relations'>
          <DetailStack {...stackProps}>{relationsComponent}</DetailStack>
        </Tabs.Panel>
      )}
      <Tabs.Panel value='documents'>
        <DetailStack {...stackProps}>{documentsComponent}</DetailStack>
      </Tabs.Panel>
      <Tabs.Panel value='red-flags'>
        <DetailStack {...stackProps}>{redFlagsComponent}</DetailStack>
      </Tabs.Panel>
    </Tabs>
  )
}
